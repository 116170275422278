import { types } from "../../types/types";

const initial_state = {
    api_reportes: "riego/api_reportes"
};

export const dataInitialReducer = (state = initial_state, action) => {
    switch (action.type) {
        case types.API_REPORTES:
            return { ...state };

        default:
            return state;
    }
}
