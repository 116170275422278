import { types } from "../../types/types";

export function initAuthSave({ username, id_aplicacion }) {
    return async (dispatch) => {
        const prepared_payload = {
            type: types.auth_Login,
            payload: {
                id_aplicacion: id_aplicacion,
                username: username
            }
        };

        dispatch(prepared_payload);
    }
}

export function finishAuth() {
    return async (dispatch) => {
        dispatch({ type: types.auth_Finish });
    }
}

export function initDataAuth(data) {
    return async (dispatch) => {
        dispatch({ type: types.auth_load_data, payload: data });
    }
}

export function initSaveDataCombos() {
    return async (dispatch) => {
        dispatch({ type: types.auth_load_dataCombos });
    }
}

