import {
    DecryptText,
    EncryptText,
    sendGetHttp,
    sendGetHttpClean,
    sendPostHttp,
    setLocalStorage,
    validateJson,
} from "../utils/helpers";

export function AuthLogin({ parameters }, remember) {
    return new Promise(async (resolve, reject) => {
        const API = "configuracion";
        const ROUTE = "login";

        try {
            const response = await sendPostHttp({ 
                parameters, 
                api: API, 
                route: ROUTE,
            });

            if (response.error) {
                return reject(response.message);
            }

            const token_encrypted = EncryptText(response.token);
            const empresa_encrypted = EncryptText(response.empresa);
            const usuario_encrypted = EncryptText(response.usuario);
            const recipiente_encrypted = EncryptText(response.recipiente);
            const id_aplicacion_encrypted = EncryptText(response.id_aplicacion.toString());
            const perfil_encrypted = EncryptText(response.dataUsuarioEmpresa.id_perfil.toString());

            response.InformacionCargaInicial.Lotes.unshift({ value: "-1" });
            response.InformacionCargaInicial.Evaluadores.unshift({ value: "-1" });
            response.InformacionCargaInicial.Cartillas.unshift({ value: "-1" });

            if(remember){
                const ids = localStorage.getItem('ids_recordados') ? JSON.parse(localStorage.getItem('ids_recordados')) : [];
                const isCreated = ids.find(x => x.id === response.id_aplicacion);
                if(!isCreated){
                    localStorage.setItem('ids_recordados', JSON.stringify([...ids, { id: response.id_aplicacion, empresa: response.empresa }]));
                }
            }

            setLocalStorage([
                { name: "known_signed", value: token_encrypted },
                { name: "cartillas", value: response.InformacionCargaInicial.Cartillas },
                // { name: "elementos", value: response.InformacionCargaInicial.Elementos },
                { name: "niveles", value: response.InformacionCargaInicial.Niveles },
                { name: "lotes", value: response.InformacionCargaInicial.Lotes },
                { name: "evaluadores", value: response.InformacionCargaInicial.Evaluadores },
                // { name: "headersitems", value: response.InformacionCargaInicial.HeadersItems },
                { name: "empresa", value: empresa_encrypted },
                { name: "usuario", value: usuario_encrypted },
                { name: "recipiente", value: recipiente_encrypted },
                { name: "id_aplicacion", value: id_aplicacion_encrypted },
                { name: "perfil", value: perfil_encrypted },
                { name: "reportes", value: response.InformacionCargaInicial.Reportes },
                { name: "graficos", value: response.InformacionCargaInicial.Graficos }
            ]);

            const credencialesUsuario = response.dataUsuarioEmpresa;
            let delimitador = null;

            delimitador = credencialesUsuario.cod_cultivo.indexOf(',');

            // let confirmacionAccesoCultivos = true;
            let arrayCodigosCultivosAsociadosUsuario = [];
            let arrayCodigosCultivos = [];

            if (delimitador === 0) {
                arrayCodigosCultivos = [credencialesUsuario.cod_cultivo];
            } else {
                arrayCodigosCultivos = credencialesUsuario.cod_cultivo.split(',');
            }

            let arrayCultivos = response.InformacionCargaInicial.Cultivos

            arrayCultivos.map((cultivoEmpresa) => {
                arrayCodigosCultivos.map((cultivoUsuario) => {
                    if (cultivoEmpresa.value.trim() === cultivoUsuario.trim()) {
                        // confirmacionAccesoCultivos = false;
                        arrayCodigosCultivosAsociadosUsuario.push(cultivoEmpresa);
                    }
                });
            });

            resolve({
                Fundos: response.InformacionCargaInicial.Fundos,
                // Cultivos: response.InformacionCargaInicial.Cultivos,
                Cultivos: arrayCodigosCultivosAsociadosUsuario,
                Campanas: response.InformacionCargaInicial.Campanas,
                id_aplicacion: response.id_aplicacion,
                username: response.usuario
            });
        } catch (error) {
            const message_error_server_500 = error === 'Failed to fetch' ? "Hay problemas con la conexión. Intentelo más tarde." : error;
            reject(message_error_server_500);
        }
    });
}

export function verifyJWTApi() {
    return new Promise(async (resolve, reject) => {
        const API = "configuracion";
        const ROUTE = "jwtVerifiyClients";
        const TokenLocalStorage = localStorage.getItem('known_signed');

        if (localStorage.length === 0) {
            return reject(false);
        }

        if (!TokenLocalStorage) {
            return reject(false);
        }

        const TOKEN_ACCESS = DecryptText(localStorage.getItem('known_signed'));

        try {
            const response = await sendGetHttp({
                api: API,
                route: ROUTE,
                token_access: TOKEN_ACCESS,
            });

            if (response.error) {
                return reject(response.message);
            }

            resolve("");
        } catch (error) {
            const ids = localStorage.getItem('ids_recordados') ? JSON.parse(localStorage.getItem('ids_recordados')) : [];
            localStorage.clear();
            localStorage.setItem('ids_recordados', JSON.stringify(ids));
            window.location.reload();
            const message_error_server_500 = 
                error === 'Failed to fetch' 
                ? "Hay problemas con la conexión. Intentelo más tarde." 
                : error;
            reject(message_error_server_500);
        }
    });
}

export function validateKeysLocalStorage() {
    if (!validateJson(localStorage.getItem('cartillas'))) {
        return false;
    }
    // if (!validateJson(localStorage.getItem('elementos'))) {
    //     return false;
    // }
    // if (!validateJson(localStorage.getItem('headersitems'))) {
    //     return false;
    // }
    if (!validateJson(localStorage.getItem('lotes'))) {
        return false;
    }
    if (!validateJson(localStorage.getItem('niveles'))) {
        return false;
    }

    const storeInitial = [
        localStorage.getItem('empresa'),
        localStorage.getItem('id_aplicacion'),
        localStorage.getItem('known_signed'),
        localStorage.getItem('usuario')
    ];

    let confirmation = true;

    for (let i = 0; i < storeInitial.length; i++) {
        if (!storeInitial[i]) {
            confirmation = null;
            break;
        }
    }

    if (!confirmation) {
        return false;
    } else {
        return true;
    }
}

export const validateInitialKeysLocalStorage = () => {
    const fundo = localStorage.getItem('nomb_fundo');
    const cultivo = localStorage.getItem('nomb_cultivo');

    if (!fundo || !cultivo) {
        const ids = localStorage.getItem('ids_recordados') ? JSON.parse(localStorage.getItem('ids_recordados')) : [];
		localStorage.clear();
        localStorage.setItem('ids_recordados', JSON.stringify(ids));
	}
}

export function AuthLogout() {
    sendGetHttpClean();
}
