import { types } from "../../types/types";
import { DecryptText, generateUuidv4 } from "../../utils/helpers";

export const authReducer = (state = {}, action) => {
    switch (action.type) {
        case types.auth_Login:
            return {
                ...state,
                uid: generateUuidv4(),
                id: action.payload.id_aplicacion,
                name: action.payload.username
            };

        case types.auth_Finish:
            return {
                ...state,
                init: true
            };

        case types.auth_load_data: {
            return {
                ...state,
                load_data: true,
                fundos: action.payload.fundos,
                cultivos: action.payload.cultivos,
                campanas: action.payload.campanas
            };
        }

        case types.auth_load_dataCombos: {
            // All or nothing
            return {
                ...state,
                lotes: JSON.parse(localStorage.getItem('lotes')).filter(lote => {
                    return lote.cod_campana === DecryptText(localStorage.getItem('cod_campana')) || lote.value === '-1'
                }),
                // niveles: JSON.parse(localStorage.getItem('niveles')).filter(nivel => {
                //     return nivel.cod_cultivo === DecryptText(localStorage.getItem('cod_cultivo'))
                //     // return true
                // }),
                niveles: listNivelesFunction(),
                cartillas: JSON.parse(localStorage.getItem('cartillas')).filter(cartilla => {
                    return cartilla.cod_cultivo === DecryptText(localStorage.getItem('cod_cultivo')) || cartilla.value === '-1'
                }),
                // elementos: JSON.parse(localStorage.getItem('elementos')).filter(elemento => {
                //     return elemento.activo === true
                // }),
                evaluadores: JSON.parse(localStorage.getItem('evaluadores')).filter(elemento => {
                    return true
                })
            };
        }

        case types.auth_Logout:
            return { };

        default:
            return state;
    }
}

function listNivelesFunction(){
    const id_aplicacion = DecryptText(localStorage.getItem('id_aplicacion'));
    let nivelesFiltered
    // ACP
    if(id_aplicacion === '496872536' || id_aplicacion === '841751763'){
        nivelesFiltered = JSON.parse(localStorage.getItem('niveles')).filter(checkNivel)
        nivelesFiltered.unshift({ value: "-1" })
    // Estándar
    } else{
        nivelesFiltered = JSON.parse(localStorage.getItem('niveles')).filter(nivel => {
            return nivel.cod_cultivo === DecryptText(localStorage.getItem('cod_cultivo'))
        })
    }
    return nivelesFiltered
}
function checkNivel(nivel) {
    return nivel.cod_campana === DecryptText(localStorage.getItem('cod_campana'));
}
